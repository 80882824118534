import Basic from "../../../containers/Admin/Layouts/Basic"
import ComponentEdit from "../../../containers/Admin/WotMaintenance/Fields"

export default function Edit() {
  return (
    <Basic menu="wot-maintenance">
          <ComponentEdit />
    </Basic>
  )
}
