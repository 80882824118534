import React, { useEffect, useState } from 'react';
import moment from 'moment'; // Importem moment.js

// Component per seleccionar la data i obtenir el timestamp Unix
const DateTimeToUnixTimestamp = ({onChange, name, label, value}) => {
  // Estat per emmagatzemar la data seleccionada i el timestamp
  const [dataHora, setDataHora] = useState(null);

  // Funció per manejar el canvi a l'input de data i hora
  const handleChange = (event) => {
    const time = moment.utc(event.target.value).unix();
    onChange({ target: { name: name, value: time } });
    setDataHora(event.target.value);
  };

  useEffect(() => {
    if (value && moment.unix(value).isValid()) {
      setDataHora(moment.unix(value).utc().format("YYYY-MM-DDTHH:mm"));
    }
  }, [value]);

  return (
    <div className="form__div">
      {/* Input de data i hora */}
      <input
        type="datetime-local"
        className="form__input"
        value={dataHora}
        onChange={handleChange}
      />
      <label htmlFor="" className="form__label">{label}</label>

      <style>
        {`
          /*===== VARIABLES CSS =====*/
          :root{
              /*===== Colores =====*/
              --first-color: #ef6f28;
              --input-color: #80868B;
              --border-color: #DADCE0;
          
              /*===== Fuente y tipografia =====*/
              --body-font: 'Roboto', sans-serif;
              --normal-font-size: 1rem;
              --small-font-size: .75rem;
          }
          
          input[type="datetime-local"]::-webkit-calendar-picker-indicator {
              background: transparent;
              bottom: 0;
              color: transparent;
              cursor: pointer;
              height: auto;
              left: 0;
              position: absolute;
              right: 0;
              top: 0;
              width: auto;
          }
          
          .form__title{
              font-weight: 400;
              margin-bottom: 3rem;
          }
          .form__div{
              position: relative;
              height: 48px;
          }
          .form__input{
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 37px;
              font-size: var(--normal-font-size);
              border: 1px solid var(--border-color);
              border-radius: .3rem;
              outline: none;
              background: none;
              padding: 0 .8rem;
              z-index: 1;
          }
          .form__label{
              position: absolute;
              left: 1rem;
              top: 1rem;
              padding: 0 .25rem;
              background-color: #fff;
              color: var(--input-color);
              transition: .3s;
          }
          .form__button{
              display: block;
              margin-left: auto;
              padding: .75rem 2rem;
              outline: none;
              border: none;
              background-color: var(--first-color);
              color: #fff;
              font-size: var(--normal-font-size);
              border-radius: .5rem;
              cursor: pointer;
              transition: .3s;
          }
          
          .form__input + .form__label{
              top: -.5rem;
              left: .8rem;
              font-size: var(--small-font-size);
              font-weight: 500;
              z-index: 10;
          }
          
          .form__input:hover{
              border: 1.5px solid #ef6f28;
          }
          
          .form__input:not(:placeholder-shown).form__input:not(:focus)+ .form__label{
              top: -.5rem;
              left: .8rem;
              font-size: var(--small-font-size);
              font-weight: 500;
              z-index: 10;
          }
          
          /*Input focus move up label*/
          .form__input:focus + .form__label{
              top: -.5rem;
              left: .8rem;
              color: var(--first-color);
              font-size: var(--small-font-size);
              font-weight: 500;
              z-index: 10;
          }
          
          /*Input focus sticky top label*/
          .form__input:not(:placeholder-shown).form__input:not(:focus)+ .form__label{
              top: -.5rem;
              left: .8rem;
              font-size: var(--small-font-size);
              font-weight: 500;
              z-index: 10;
          }
          
          /*Input focus*/
          .form__input:focus{
              border: 2px solid var(--first-color);
          }
        `}
      </style>
    </div>
  );
};

export default DateTimeToUnixTimestamp;