import React, { useState, useEffect } from "react"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useHistory, useParams } from "react-router-dom"
import useAuth from "../../../../hooks/useAuth"
import {createApi, getWotMaintenanceApi, updateApi} from "../../../../services/admin/wotMaintenance"
import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import DateTimeToUnixTimestamp from "../../../../components/Admin/Inputs/DateTimeToUnixTimestamp";
import ArrowBackIos from "@material-ui/icons/ArrowBackIos"
import Autocomplete from "@material-ui/lab/Autocomplete";
import {listApi} from "../../../../services/admin/request";
import moment from "moment";

export default function Fields() {
    const { id } = useParams()
    const { logout } = useAuth()
    const history = useHistory()

    const [msgSuccess, setMsgSuccess] = useState(false)
    const [msgDanger, setMsgDanger] = useState(null)
    const [loading, setLoading] = useState(true)
    const [listCompany, setListCompany] = useState([])
    const [listRegion, setListRegion] = useState([])
    const [defaultCompany, setDefaultCompany] = useState(null)
    const [defaultRegion, setDefaultRegion] = useState(null)

    const [initialValues, setInitialValues] = useState({
        idCompany: null,
        idRegion: null,
        dateFrom: null,
        dateTo: null,
        description: "We are performing maintenance operations, please come back later."
    })

    const changeCompany = (event, value) => {
        if (value !== null) {
            formik.values.idCompany = value.id
            setDefaultCompany({
                id: value.id,
                name: value.name
            })
            fetchRegions(value.id); // Fetch regions with the selected company ID
        } else {
            setDefaultCompany(null)
            formik.values.idCompany = null
            fetchRegions(); // Fetch regions without company ID
        }
    }

    const changeRegion = (event, value) => {
        if (value !== null) {
            formik.values.idRegion = value.id
            setDefaultRegion({
                id: value.id,
                name: value.name
            })
        } else {
            setDefaultRegion(null)
            formik.values.idRegion = null
        }
    }

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object(validationSchema),
        enableReinitialize: true,
        onSubmit: async (formData) => {
            setMsgSuccess(false)
            setMsgDanger(null)

            if (!formik.values.idCompany || !formik.values.idRegion) {
                setMsgDanger(["Company and Zone are required"])
                return
            }

            if (id){
                const response = await updateApi(logout, formData)
                if (response.status) {
                    setMsgSuccess(true)
                } else {
                    setMsgDanger(response.errors)
                }
            } else {
                const response = await createApi(formData)
                if (response.status) {
                    history.push(`/admin/wot-maintenance/edit/${response.data.id}`)
                } else {
                    setMsgDanger(response.errors)
                }
            }

        },
    });

    const fetchData = async () => {
        if (id) {
            const response = await getWotMaintenanceApi(logout, {id: id})
            if (response.status) {
                setInitialValues({
                    id: response.data.id,
                    idCompany: response.data.idCompany,
                    idRegion: response.data.idRegion,
                    //dateFrom: moment.unix(response.data.dateFrom).utc().format("YYYY-MM-DD"),
                    //dateTo: moment.unix(response.data.dateTo).utc().format("YYYY-MM-DD"),
                    dateFrom: response.data.dateFrom,
                    dateTo: response.data.dateTo,
                    description: response.data.description,
                })
                setDefaultCompany(response.data.idCompany)
                setDefaultRegion(response.data.idRegion)
            }
        }
        setLoading(false)
    };

    const fetchCompanies = async () => {
        const response = await listApi(logout, "company");
        if (response.status) {
            const temp = response.data.map((doc) => {
                return {
                    id: doc.id,
                    name: doc.name,
                    value: doc.id,
                    ...doc,
                }
            })
            setListCompany(temp)
        }
    };

    const fetchRegions = async (idCompany = null) => {
        const response = await listApi(logout, "region", idCompany ? `?idCompany=${idCompany}` : "");
        if (response.status) {
            const temp = response.data.map((doc) => {
                return {
                    id: doc.id,
                    name: doc.name,
                    value: doc.id,
                    ...doc,
                }
            })
            setListRegion(temp)
        }
    };

    useEffect(() => {
        fetchData();
        fetchCompanies();
        fetchRegions();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    if (loading) return ""

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12} lg={12}>
                        <Button
                            type="button"
                            color="primary"
                            href="/admin/wot-maintenance/management"
                            style={{
                                float: "left"
                            }}
                        >
                            <ArrowBackIos />
                            BACK TO LIST
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                        <h2 className="p-0">WOT Maintenance Settings</h2>
                    </Grid>
                </Grid>

                {msgSuccess && (
                    <Grid item xs={12} md={12} lg={12}>
                        <Box className="alert-success">
                            Data has been saved correctly
                        </Box>
                    </Grid>
                )}

                {msgDanger && (
                    <Grid item xs={12} md={12} lg={12}>
                        <Box className="alert-danger">
                            {msgDanger.map((item) => {
                                return (
                                    <span>
                                        {" "}
                                        {item} <br />{" "}
                                    </span>
                                );
                            })}
                        </Box>
                    </Grid>
                )}

                <Box border={1} className="p-20 box-default">
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={12} lg={12} className="border-bottom">
                            <h5 className="p-0 mt-0">Info</h5>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            {id && (
                                <div className="my-2">
                                    <TextField
                                        variant="outlined"
                                        label="* Maintenance ID"
                                        className="form-control"
                                        name="id"
                                        type="text"
                                        value={formik.values.id}
                                        onChange={formik.handleChange}
                                        disabled
                                        fullWidth
                                        size="small"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </div>
                            )}

                            <div className="my-2 mb-2">
                                <Autocomplete
                                    options={listCompany}
                                    value={defaultCompany}
                                    getOptionLabel={(option) => option.name || ""}
                                    getOptionSelected={(option, value) => option.value === value.value }
                                    onChange={changeCompany}
                                    fullWidth
                                    disabled={loading}
                                    size="small"
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="* Company "
                                            variant="outlined"
                                            name="idCompany"
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    )}
                                />
                            </div>

                            <div className="my-2 mb-2">
                                <Autocomplete
                                    options={listRegion}
                                    value={defaultRegion}
                                    getOptionLabel={(option) => option.name || ""}
                                    getOptionSelected={(option, value) => option.value === value.value }
                                    onChange={changeRegion}
                                    fullWidth
                                    disabled={loading}
                                    size="small"
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="* Region "
                                            variant="outlined"
                                            name="idRegion"
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    )}
                                />
                            </div>

                            <div className="my-2">
                                <DateTimeToUnixTimestamp
                                    variant="outlined"
                                    label="* Date From"
                                    className="form-control"
                                    name="dateFrom"
                                    type="date"
                                    value={formik.values.dateFrom}
                                    onChange={formik.handleChange}
                                    fullWidth
                                    size="small"
                                    InputLabelProps={{ shrink: true }}
                                />
                                {formik.errors.dateFrom && (
                                    <p className="text-100 text-danger">
                                        {formik.errors.dateFrom}
                                    </p>
                                )}
                            </div>
                            <div></div>
                            

                            <div className="my-2">
                                <DateTimeToUnixTimestamp
                                    variant="outlined"
                                    label="* Date To"
                                    className="form-control"
                                    name="dateTo"
                                    type="date"
                                    value={formik.values.dateTo}
                                    onChange={formik.handleChange}
                                    fullWidth
                                    size="small"
                                    InputLabelProps={{ shrink: true }}
                                />
                                {formik.errors.dateTo && (
                                    <p className="text-100 text-danger">
                                        {formik.errors.dateTo}
                                    </p>
                                )}
                            </div>

                            <div className="my-2">
                                <TextField
                                    variant="outlined"
                                    label="* Statement"
                                    className="form-control"
                                    name="description"
                                    type="text"
                                    value={formik.values.description}
                                    onChange={formik.handleChange}
                                    fullWidth
                                    size="small"
                                    InputLabelProps={{ shrink: true }}
                                />
                                {formik.errors.description && (
                                    <p className="text-100 text-danger">
                                        {formik.errors.description}
                                    </p>
                                )}
                            </div>

                        </Grid>
                    </Grid>
                </Box>

                <Grid container spacing={3} className="p-20">
                    <Grid item xs={12} md={6} lg={6}>&nbsp;</Grid>
                    <Grid item xs={6} md={3} lg={3}>
                        <Button
                            type="button"
                            color="secondary"
                            href="/admin/wot-maintenance/management"
                            fullWidth
                        >
                            { id ? ( <><ArrowBackIos /> Back to list </>) : "Cancel" }
                        </Button>
                    </Grid>
                    <Grid item xs={6} md={3} lg={3}>
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            fullWidth
                        >
                            { id ? "Modify" : "Create" }
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </>
    )
}

function validationSchema() {
    return {
        id: Yup.number(),
        dateFrom: Yup.string().required("Date From is required"),
        dateTo: Yup.string().required("Date To is required"),
        description: Yup.string().required("Description is required"),
        idCompany: Yup.number().required("Company is required"),
        idRegion: Yup.number().required("Zone is required"),
    };
}
