import Basic from "../../../containers/Admin/Layouts/Basic"
import ContainersCreate from "../../../containers/Admin/WotMaintenance/Fields"

export default function Create() {
  return (
    <Basic menu="wot-maintenance">
          <ContainersCreate />
    </Basic>
  )
}
