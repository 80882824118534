import { BASE_PATH } from "../../utils/constants";
import axios from "axios";

const baseUrl = `${BASE_PATH}/v1/api/auth/maintenance`;

export function apiMaintenance() {
    const token = localStorage.getItem("token");
    const config = token ? {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    } : {};

    return axios.get(`${baseUrl}`, config);
}
