import React from "react";
import {BrowserRouter as Router, Route, Switch,} from "react-router-dom";

import Homepage from "./pages/homepage"
import Login from "./pages/login"
import Maintenance from "./pages/maintenance"
import Register from "./pages/register"
import RequestResetPassword from "./pages/requestResetPassword";
import ResetPassword from './pages/resetPassword'
import UserDashOrder from "./pages/user/dash/order"
import UserDashReorder from "./pages/user/dash/reorder/reorder"
import UserDashTransactionHistory from "./pages/user/dash/transaction/history"
import UserDashTransactionDetails from "./pages/user/dash/transaction/details"
import UserDashTransactionUpdateOrder from "./pages/user/dash/transaction/update"
import UserDashReportsProduct from "./pages/user/dash/report/product"
import UserDashReportsStock from "./pages/user/dash/report/stock"
import UserDashReportsStore from "./pages/user/dash/report/store"
import UserDashReportsProductionPlan from "./pages/user/dash/report/productionPlan"
import UserDashReportsSupplyChain from "./pages/user/dash/report/supplyChain"
import UserDashReportsAvailability from "./pages/user/dash/report/availability"
import UserDashReportsAvailabilityv2 from "./pages/user/dash/report/availabilityv2"
import UserDashReportsMaxOrder from "./pages/user/dash/report/maxOrder"
import UserDashReportsStockQty from "./pages/user/dash/report/stockqty"
import AdminImport from "./pages/admin/import"

// User
import AdminUserManagement from "./pages/admin/users/management"
import AdminUserEdit from "./pages/admin/users/edit"
import AdminUserCreate from "./pages/admin/users/create"

// Company
import AdminCompanyManagement from "./pages/admin/company/management"
import AdminCompanyEdit from "./pages/admin/company/edit"
import AdminCompanyCreate from "./pages/admin/company/create"

// Region
import AdminRegionManagement from "./pages/admin/region/management"
import AdminRegionEdit from "./pages/admin/region/edit"
import AdminRegionCreate from "./pages/admin/region/create"

// Region / Subregion
import AdminSubregionEdit from "./pages/admin/region/subregion/edit"
import AdminSubregionCreate from "./pages/admin/region/subregion/create"

// Location
import AdminLocationManagement from "./pages/admin/location/management"
import AdminLocationEdit from "./pages/admin/location/edit"
import AdminLocationCreate from "./pages/admin/location/create"
import AdminStoreCategoryManagement from "./pages/admin/storeCategory/management"
import AdminStoreCategoryEdit from "./pages/admin/storeCategory/edit"
import AdminStoreCategoryCreate from "./pages/admin/storeCategory/create"

// Product
import AdminProductManagement from "./pages/admin/product/management"
import AdminProductEdit from "./pages/admin/product/edit"
import AdminProductCreate from "./pages/admin/product/create"

// Invoice Entities
import AdminInvoiceEntitiesManagement from "./pages/admin/invoiceEntities/management"
import AdminInvoiceEntitiesCreate from "./pages/admin/invoiceEntities/create"
import AdminInvoiceEntitiesEdit from "./pages/admin/invoiceEntities/edit"

// Product type
import AdminProductTypeManagement from "./pages/admin/productType/management"
import AdminProductTypeCreate from "./pages/admin/productType/create"
import AdminProductTypeEdit from "./pages/admin/productType/edit"

// Currency
import AdminCurrencyManagement from "./pages/admin/currency/management"
import AdminCurrencyCreate from "./pages/admin/currency/create"
import AdminCurrencyEdit from "./pages/admin/currency/edit"

// Currency region
import AdminCurrencyRegionManagement from "./pages/admin/currencyRegion/management"
import AdminCurrencyRegionCreate from "./pages/admin/currencyRegion/create"
import AdminCurrencyRegionEdit from "./pages/admin/currencyRegion/edit"

// Warehouse
import AdminWarehouseManagement from "./pages/admin/warehouse/management"
import AdminWarehouseEdit from "./pages/admin/warehouse/edit"
import AdminWarehouseCreate from "./pages/admin/warehouse/create"
import AdminWarehouseStoreProduct from "./pages/admin/warehouse/storeProduct/management"

// Transaction
import AdminTransactionManagement from "./pages/admin/transacion/management"
import AdminTransactionLogManagement from "./pages/admin/transactionLog/management"
import AdminTransactionEdit from "./pages/admin/transacion/edit"
import AdminTransactionShipment from "./pages/admin/transacion/shipment/edit"
import AdminShipmentManagement from "./pages/admin/shipment/management"

// Transaction Grouping
import AdminTransactionGroupingEdit from "./pages/admin/transactionGrouping/edit"

// Auth
import Auth from "./auth"

// Category
import AdminCategoryManagement from "./pages/admin/category/management"
import AdminCategoryCreate from "./pages/admin/category/create"
import AdminCategoryEdit from "./pages/admin/category/edit"

// Catalog
import AdminCatalogManagement from "./pages/admin/catalog/management"
import AdminCatalogCreate from "./pages/admin/catalog/create"
import AdminCatalogEdit from "./pages/admin/catalog/edit"
// Country
import AdminCountryManagement from "./pages/admin/country/management"
import AdminCountryEdit from "./pages/admin/country/edit"
import AdminCountryCreate from "./pages/admin/country/create"

// Container
import AdminContainerManagement from "./pages/admin/container/management";
import AdminContainerEdit from "./pages/admin/container/edit";

// Po
import AdminPoManagement from "./pages/admin/po/management";
import AdminPoEdit from "./pages/admin/po/edit";

// Tariff
import AdminTariffManagement from "./pages/admin/tariff/management";
import AdminTariffEdit from "./pages/admin/tariff/edit";

// Shipping Charge
import AdminShippingChargeManagement from "./pages/admin/shippingCharge/management"
import AdminShippingChargeCreate from "./pages/admin/shippingCharge/create"
import AdminShippingChargeEdit from "./pages/admin/shippingCharge/edit"

import {ToastContainer} from "react-toastify";

// Logs
import AdminLogMail from "./pages/admin/Log/LogMail";
import AdminLogNetSuite from "./pages/admin/Log/LogNetSuite";
import AdminLogCeline from "./pages/admin/Log/LogCeline";
import AdminLogActivity from "./pages/admin/Log/LogActivity";

// Doc
import AdminDoc from "./pages/admin/doc/";
import {Microsoft} from "./containers/Auth/Microsoft";
import Logout from "./containers/Auth/Logout";

// Category
import AdminWotMaintenanceManagement from "./pages/admin/wotMaintenance/management"
import AdminWotMaintenanceCreate from "./pages/admin/wotMaintenance/create"
import AdminWotMaintenanceEdit from "./pages/admin/wotMaintenance/edit"


export default function App() {

    return (
        <>
            {/*<Microsoft />*/}
            <Router>
                <Auth>
                    <Switch>

                        <Route exact path="/" render={(props) => <Homepage/>}/>
                        <Route exact path="/login" render={(props) => <Login/>}/>
                        <Route exact path="/logout" render={(props) => <Logout/>}/>
                        <Route exact path="/maintenance" render={(props) => <Maintenance/>}/>
                        <Route exact path="/register" render={(props) => <Register/>}/>
                        <Route exact path="/request-reset-password" render={(props) => <RequestResetPassword/>}/>
                        <Route exact path="/reset-password/:token" render={(props) => <ResetPassword/>}/>
                        <Route exact path="/dash/order" render={(props) => <UserDashOrder/>}/>
                        <Route exact path="/dash/reorder/:id" render={(props) => <UserDashReorder/>}/>
                        <Route exact path="/dash/order-history" render={(props) => <UserDashTransactionHistory/>}/>
                        <Route exact path="/dash/order-history/details/:id" render={(props) => <UserDashTransactionDetails/>}/>
                        <Route exact path="/dash/order/:id" render={(props) => <UserDashTransactionUpdateOrder/>}/>
                        <Route exact path="/dash/reports-product" render={(props) => <UserDashReportsProduct/>}/>
                        <Route exact path="/dash/reports-store" render={(props) => <UserDashReportsStore/>}/>
                        <Route exact path="/dash/reports-stock" render={(props) => <UserDashReportsStock/>}/>
                        <Route exact path="/dash/reports-productionPlan" render={(props) => <UserDashReportsProductionPlan/>}/>
                        <Route exact path="/dash/reports-supplyChain" render={(props) => <UserDashReportsSupplyChain/>}/>
                        <Route exact path="/dash/reports-msl" render={(props) => <UserDashReportsAvailability/>}/>
                        <Route exact path="/dash/reports-consumption" render={(props) => <UserDashReportsAvailabilityv2/>}/>
                        <Route exact path="/dash/reports-max-order" render={(props) => <UserDashReportsMaxOrder/>}/>
                        <Route exact path="/dash/reports-stock-qty" render={(props) => <UserDashReportsStockQty/>}/>

                        {/* ###### ADMIN ROUTE ###### */}

                        {/* USER */}
                        <Route exact path="/admin/users/management" render={(props) => <AdminUserManagement/>}/>
                        <Route exact path="/admin/users/edit/:id" render={(props) => <AdminUserEdit/>}/>
                        <Route exact path="/admin/users/create" render={(props) => <AdminUserCreate/>}/>

                        {/* COMPANY */}
                        <Route exact path="/admin/company/management" render={(props) => <AdminCompanyManagement/>}/>
                        <Route exact path="/admin/company/edit/:id" render={(props) => <AdminCompanyEdit/>}/>
                        <Route exact path="/admin/company/create" render={(props) => <AdminCompanyCreate/>}/>

                        {/* COMPANY */}
                        <Route exact path="/admin/country/management" render={(props) => <AdminCountryManagement/>}/>
                        <Route exact path="/admin/country/edit/:id" render={(props) => <AdminCountryEdit/>}/>
                        <Route exact path="/admin/country/create" render={(props) => <AdminCountryCreate/>}/>

                        {/* REGION */}
                        <Route exact path="/admin/region/management" render={(props) => <AdminRegionManagement/>}/>
                        <Route exact path="/admin/region/edit/:id/:tab" render={(props) => <AdminRegionEdit/>}/>
                        <Route exact path="/admin/region/create" render={(props) => <AdminRegionCreate/>}/>

                        {/* REGION / SUBREGION */}
                        <Route exact path="/admin/region/subregion/edit/:idRegion/:id"
                               render={(props) => <AdminSubregionEdit/>}/>
                        <Route exact path="/admin/region/subregion/create/:idRegion"
                               render={(props) => <AdminSubregionCreate/>}/>

                        {/* LOCATION */}
                        <Route exact path="/admin/store/management" render={(props) => <AdminLocationManagement/>}/>
                        <Route exact path="/admin/store/edit/:id/:tab" render={(props) => <AdminLocationEdit/>}/>
                        <Route exact path="/admin/store/create" render={(props) => <AdminLocationCreate/>}/>

                        {/* PRODUCT */}
                        <Route exact path="/admin/product/management" render={(props) => <AdminProductManagement/>}/>
                        <Route exact path="/admin/product/edit/:id/:tab" render={(props) => <AdminProductEdit/>}/>
                        <Route exact path="/admin/product/create" render={(props) => <AdminProductCreate/>}/>
                        <Route exact path="/admin/store-category/edit/:id"
                               render={(props) => <AdminStoreCategoryEdit/>}/>
                        <Route exact path="/admin/store-category/create"
                               render={(props) => <AdminStoreCategoryCreate/>}/>
                        <Route exact path="/admin/store-category/management"
                               render={(props) => <AdminStoreCategoryManagement/>}/>

                        {/* INVOICE ENTITIES */}
                        <Route exact path="/admin/invoice-entities/management"
                               render={(props) => <AdminInvoiceEntitiesManagement/>}/>
                        <Route exact path="/admin/invoice-entities/edit/:id"
                               render={(props) => <AdminInvoiceEntitiesEdit/>}/>
                        <Route exact path="/admin/invoice-entities/create"
                               render={(props) => <AdminInvoiceEntitiesCreate/>}/>

                        {/* PRODUCT TYPE */}
                        <Route exact path="/admin/product-type/management"
                               render={(props) => <AdminProductTypeManagement/>}/>
                        <Route exact path="/admin/product-type/edit/:id" render={(props) => <AdminProductTypeEdit/>}/>
                        <Route exact path="/admin/product-type/create" render={(props) => <AdminProductTypeCreate/>}/>

                        {/* CURRENCY */}
                        <Route exact path="/admin/currency/management" render={(props) => <AdminCurrencyManagement/>}/>
                        <Route exact path="/admin/currency/edit/:id" render={(props) => <AdminCurrencyEdit/>}/>
                        <Route exact path="/admin/currency/create" render={(props) => <AdminCurrencyCreate/>}/>

                        {/* CURRENCY REGION */}
                        <Route exact path="/admin/currency-region/management"
                               render={(props) => <AdminCurrencyRegionManagement/>}/>
                        <Route exact path="/admin/currency-region/edit/:idProduct/:id"
                               render={(props) => <AdminCurrencyRegionEdit/>}/>
                        <Route exact path="/admin/currency-region/create/:idProduct"
                               render={(props) => <AdminCurrencyRegionCreate/>}/>

                        {/* WAREHOUSE */}
                        <Route exact path="/admin/warehouse/management"
                               render={(props) => <AdminWarehouseManagement/>}/>
                        <Route exact path="/admin/warehouse/edit/:id/:tab" render={(props) => <AdminWarehouseEdit/>}/>
                        <Route exact path="/admin/warehouse/create" render={(props) => <AdminWarehouseCreate/>}/>
                        <Route exact path="/admin/warehouse/store-product/:idWarehouse/:idLocation"
                               render={(props) => <AdminWarehouseStoreProduct/>}/>

                        {/* TRANSACTION */}
                        <Route exact path="/admin/transaction/management"
                               render={(props) => <AdminTransactionManagement/>}/>
                        <Route exact path="/admin/transactionLog/management"
                               render={(props) => <AdminTransactionLogManagement/>}/>
                        <Route exact path="/admin/transaction/edit/:id/:tab"
                               render={(props) => <AdminTransactionEdit/>}/>
                        <Route exact path="/admin/transaction/shipment/create/:idTransaction"
                               render={(props) => <AdminTransactionShipment/>}/>
                        <Route exact path="/admin/transaction/shipment/edit/:idTransaction/:id"
                               render={(props) => <AdminTransactionShipment/>}/>
                        <Route exact path="/admin/shippings/management" render={(props) => <AdminShipmentManagement/>}/>

                        {/* TRANSACTION GROUPING */}
                        <Route exact path="/admin/transaction-grouping/edit/:id/:tab"
                               render={(props) => <AdminTransactionGroupingEdit/>}/>

                        {/* CATEGORY */}
                        <Route exact path="/admin/category/management" render={(props) => <AdminCategoryManagement/>}/>
                        <Route exact path="/admin/category/edit/:id" render={(props) => <AdminCategoryEdit/>}/>
                        <Route exact path="/admin/category/create" render={(props) => <AdminCategoryCreate/>}/>

                        {/* CATALOG */}
                        <Route exact path="/admin/catalog/management" render={(props) => <AdminCatalogManagement/>}/>
                        <Route exact path="/admin/catalog/edit/:id" render={(props) => <AdminCatalogEdit/>}/>
                        <Route exact path="/admin/catalog/create" render={(props) => <AdminCatalogCreate/>}/>

                        {/* CONTAINER */}
                        <Route exact path="/admin/containers/management" render={(props) => <AdminContainerManagement/>}/>
                        <Route exact path="/admin/containers/edit/:id" render={(props) => <AdminContainerEdit/>}/>
                        <Route exact path="/admin/containers/create/:idWarehouse" render={(props) => <AdminContainerEdit/>}/>

                        {/* TARIFF */}
                        <Route exact path="/admin/tariff/management" render={(props) => <AdminTariffManagement/>}/>
                        <Route exact path="/admin/tariff/edit/:id" render={(props) => <AdminTariffEdit/>}/>
                        <Route exact path="/admin/tariff/create" render={(props) => <AdminTariffEdit/>}/>

                        {/* PO */}
                        <Route exact path="/admin/po/management" render={(props) => <AdminPoManagement/>}/>
                        <Route exact path="/admin/po/edit/:id" render={(props) => <AdminPoEdit/>}/>
                        <Route exact path="/admin/po/create" render={(props) => <AdminPoEdit/>}/>

                        {/* SHIPPING CHARGE */}
                        <Route exact path="/admin/shipping-charge/management" render={(props) => <AdminShippingChargeManagement/>}/>
                        <Route exact path="/admin/shipping-charge/edit/:id" render={(props) => <AdminShippingChargeEdit/>}/>
                        <Route exact path="/admin/shipping-charge/create" render={(props) => <AdminShippingChargeCreate/>}/>

                        {/* LOG */}
                        <Route exact path="/admin/log/mail" render={(props) => <AdminLogMail/>}/>
                        <Route exact path="/admin/log/netsuite" render={(props) => <AdminLogNetSuite/>}/>
                        <Route exact path="/admin/log/celine" render={(props) => <AdminLogCeline/>}/>
                        <Route exact path="/admin/log/activity" render={(props) => <AdminLogActivity/>}/>

                        {/* CATEGORY */}
                        <Route exact path="/admin/wot-maintenance/management" render={(props) => <AdminWotMaintenanceManagement/>}/>
                        <Route exact path="/admin/wot-maintenance/edit/:id" render={(props) => <AdminWotMaintenanceEdit/>}/>
                        <Route exact path="/admin/wot-maintenance/create" render={(props) => <AdminWotMaintenanceCreate/>}/>

                        {/* DOC */}
                        <Route exact path="/admin/doc/install" render={(props) => <AdminDoc/>}/>

                        {/* IMPORT */}
                        <Route exact path="/admin/import" render={(props) => <AdminImport/>}/>
                    </Switch>
                </Auth>
                <ToastContainer theme="colored"/>
            </Router>
        </>
    );
}

