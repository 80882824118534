import React, { useEffect, useState, useMemo } from "react";
import moment from "moment";
import { apiList, deleteApi } from "../../../../services/admin/wotMaintenance";
import useAuth from "../../../../hooks/useAuth";
import TableBase from "../../../../components/Admin/TableBase";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";

export default function Management() {
    const [maintenances, setMaintenances] = useState(null)
    const [loading, setLoading] = useState(false);
    const { logout } = useAuth()

    const handleDelete = (id) => async (e) => {
        e.preventDefault();
        setLoading(true);
        const response = await deleteApi(id);
        if (response && response.status) {
            getData();
        }
        setLoading(false);
    };

    const getData = async () => {
        const response = await apiList({logout});
        if (response.status) {
            const temp = response.data?.data?.map((doc) => {
                let href = `/admin/wot-maintenance/edit/${doc.id}`

                let companyName = ""
                if(doc.idCompany){
                    companyName = doc.idCompany.name
                }

                let regionName = ""
                if(doc.idRegion){
                    regionName = doc.idRegion.name
                }

                let dateFrom = moment.unix(doc.dateFrom).isValid() ? moment.unix(doc.dateFrom).utc().format('DD-MMM-YYYY hh:mm A') : "";
                let dateTo = moment.unix(doc.dateTo).isValid() ? moment.unix(doc.dateTo).utc().format('DD-MMM-YYYY hh:mm A') : "";

                return {
                    ...doc,
                    id: doc.id,
                    companyName: companyName,
                    regionName: regionName,
                    dateFrom: dateFrom,
                    dateTo: dateTo,
                    actions: (
                        <>
                            <Link color="primary" href={href}>
                                Edit
                            </Link>
                            <span> | </span>
                            <Link 
                                color="primary" 
                                href={"#"} 
                                onClick={handleDelete(doc.id)}
                                disabled={loading}
                            >
                                {loading ? "Deleting..." : "Delete"}
                            </Link>
                        </>
                    ),
                };
            });
            setMaintenances(temp)
        }
    }

    const columns = useMemo(
        () => [
            {
                Header: "COMPANY",
                accessor: "companyName",
            },
            {
                Header: "ZONE",
                accessor: "regionName",
            },
            {
                Header: "DATE FROM",
                accessor: "dateFrom",
            },
            {
                Header: "DATE TO",
                accessor: "dateTo",
            },
            {
                Header: "STATEMENT",
                accessor: "description",
            },
            {
                Header: "ACTIONS",
                accessor: "actions",
            },
        ],
        []
    )

    useEffect(() => {
        getData();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    if (!maintenances) return "";

    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={6} md={6} lg={6}>
                    <h1>WOT Maintenance</h1>
                    <p style={{textDecoration:"bold"}}>
                        The users assigned to the zones under maintenance hold the roles of Store, 
                        Multi-store, and Approver/Region. Please note that the dates are in UTC 0.
                    </p>
                </Grid>
                <Grid item xs={6} md={6} lg={6}>
                    <Button
                        variant="contained"
                        color="primary"
                        style={{ float: "right", margin: "20px 0px" }}
                        href="/admin/wot-maintenance/create"
                    >
                        + add
                    </Button>
                </Grid>
            </Grid>

            <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12}>
                    <Box border={1} className="box-default">
                        <TableBase
                            columns={columns} 
                            data={maintenances} 
                            search={true}
                            selectedRows={maintenances}
                            onSelectedRowsChange={setMaintenances}
                        />
                    </Box>
                </Grid>
            </Grid>
        </>
    );
}
