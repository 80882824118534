import * as React from 'react';
import {ThemeProvider} from '@mui/material/styles';
import {Box} from '@mui/material';
import logo from "../../../assets/images/logo-small-new.svg";
import {useEffect} from "react";
import {apiMaintenance} from "../../../services/auth/maintenence";
import {useHistory} from "react-router-dom";

export default function Maintenance() {

    const history = useHistory();
    const [comeBackTime, setComeBackTime] = React.useState("")

    useEffect(() => {
        apiMaintenance({}).then(resp => {
            if (!resp.data?.isActive) {
                history.push("/login")
            } else {
                setComeBackTime(resp.data?.comeBackTime)
            }
        })
    }, [])

    const logout = () => {
        history.push("/logout")
    }

    return (
        <ThemeProvider >
            <Box sx={{display: 'flex'}}>
                <Box
                    component="main"
                    sx={{
                        backgroundColor: '#ffffff',
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto',
                        textAlign: "center"
                    }}
                >
                    <div style={{"height": "20vh"}}></div>

                    <div style={{width: 300, margin: 'auto'}}><img src={logo} style={{width: '100%'}} /></div>

                    <h1 style={{"font-size": "100px", "margin": "0px", textAlign: "center"}}>Maintenance</h1>
                    <h2 style={{textAlign: "center"}}>{comeBackTime !== "" ? (<>{comeBackTime}</>) : ''}</h2>
                    <br /><br />

                    <div style={{textAlign: "center"}}>
                        <button
                            type="button"
                            style={{
                                backgroundColor: "#ef6f28",
                                border: "none",
                                borderRadius: "5px",
                                color: "white",
                                padding: "5px 30px 5px 30px",
                            }}
                            onClick={logout}
                        >
                            Go to Login Screen
                        </button>
                    </div>
                </Box>
            </Box>
        </ThemeProvider>
    )
}
